
// import axios from 'axios';
// const API_IP = 'chatserver.onepgr.com'; 
// // const API_IP = '127.0.0.1';

// // const API_PORT = 5001; 
// const API_PROTOCOL = 'https'; // Use 'http' for development if needed


// // Construct the base URL
// const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;

// axios.defaults.withCredentials = true;

// console.log('API Base URL:', API_BASE_URL); // Log the base URL for debugging

// export const sendProcessedDataToBackend = async (processedData) => {
//   try {
//     const requestBody = {
//       prompt: processedData.originalPrompt,
//       params: processedData.extractedInfo
//     };
//     console.log("Sending data to backend:", JSON.stringify(requestBody, null, 2));

//     const response = await fetch(`${API_BASE_URL}/api/process`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),

//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       console.error("Server response:", errorData);
//       throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('API call failed:', error);
//     throw error;
//   }
// };

// export const enrichPeopleData = async (peopleToEnrich, enrichmentType) => {
//   try {
//     const requestBody = {
//       prompt: "enrich people",
//       params: {
//         people: peopleToEnrich,
//         enrich_type: enrichmentType
//       }
//     };
//     console.log("Sending enrichment data to backend:", JSON.stringify(requestBody, null, 2));

//     const response = await fetch(`${API_BASE_URL}/api/process`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(requestBody),
      
//     });

//     if (!response.ok) {
//       const errorData = await response.json();
//       console.error("Server response:", errorData);
//       throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
//     }

//     return await response.json();
//   } catch (error) {
//     console.error('Enrichment API call failed:', error);
//     throw error;
//   }
// };

// export const testBackendConnection = async () => {
//   try {
//     const response = await fetch(`${API_BASE_URL}/api/test`, {
//       method: 'POST',
//     });
//     if (!response.ok) {
//       throw new Error(`HTTP error! status: ${response.status}`);
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('Backend connection test failed:', error);
//     throw error;
//   }
// };

// export const createNewChat = async (userId, type = 'chat', domainName = '', linkedinUrl = '') => {
//   console.log(userId)
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/chat/new`, {
//       user_id: userId,
//       type,
//       domain_name: domainName,
//       linkdin_url: linkedinUrl
//     });

//     if (response.data.status === 1) {
//       return response.data.sessionId;
//     } else {
//       throw new Error(response.data.message || 'Failed to create new chat');
//     }
//   } catch (error) {
//     console.error('Error creating new chat:', error);
//     throw error;
//   }
// };

// export const fetchChatHistory = async (sessionId, userId, objectId = null) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/chat/history`, {
//       sessionId,
//       user_id: userId,
//       object_id: objectId
//     });

//     if (response.data.status === 1) {
//       return response.data.history;
//     } else {
//       throw new Error(response.data.message || 'Failed to fetch chat history');
//     }
//   } catch (error) {
//     console.error('Error fetching chat history:', error);
//     throw error;
//   }
// };

// export const getRecentChats = async (userId, type = null, objectId = null) => {
//   console.log(userId)
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/sessions`, {
//       user_id: userId,
//       type,
//       object_id: objectId
//     });

//     if (response.data.status === 1) {
//       return response.data.accountSessions;
//     } else {
//       throw new Error(response.data.message || 'Failed to fetch recent chats');
//     }
//   } catch (error) {
//     console.error('Error fetching recent chats:', error);
//     throw error;
//   }
// };

// export const sendMessageToChat = async (sessionId, userMessage, botMessage, userId, type = 'chat', domainName = '', linkedinUrl = '') => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/chat/insert`, {
//       sessionId: sessionId,
//       userMessage: userMessage,
//       botMessage: botMessage,
//       userChatTime: new Date().toISOString(),
//       aiChatTime: new Date().toISOString(),
//       object_id: '', // Add if needed
//       user_id: userId,
//       type: type,
//       domain_name: domainName,
//       linkdin_url: linkedinUrl
//     });

//     if (response.data.status === 1) {
//       return response.data.sessionId;
//     } else {
//       throw new Error(response.data.message || 'Failed to send message');
//     }
//   } catch (error) {
//     console.error('Error sending message:', error);
//     throw error;
//   }
// };



import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const API_IP = 'chatserver.onepgr.com';
//  const API_IP = '127.0.0.1';
const API_PROTOCOL = 'https';
const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;

axios.defaults.withCredentials = true;

console.log('API Base URL:', API_BASE_URL);

export const sendProcessedDataToBackend = async (processedData) => {
  console.log(processedData)
  console.log("Sending data to backend:", JSON.stringify(processedData, null, 2));
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, processedData);
    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('API call failed:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

export const enrichPeopleData = async (peopleToEnrich, enrichmentType) => {
  try {
    const requestBody = {
      prompt: "enrich people",
      intents: [
        {
          type: "enrich",
          extractedInfo: {
            enrichType: enrichmentType,
            people: peopleToEnrich
          }
        }
      ]
    };
    console.log("Sending enrichment data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('Enrichment API call failed:', error);
    throw error;
  }
};

export const testBackendConnection = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/test`);
    return response.data;
  } catch (error) {
    console.error('Backend connection test failed:', error);
    throw error;
  }
};

export const createNewChat = async (userId, type, domainName = '', linkedinUrl = '') => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/new`, {
      user_id: userId,
      type,
      domain_name: domainName,
      linkdin_url: linkedinUrl
    });

    if (response.data.status === 1) {
      return response.data.sessionId;
    } else {
      throw new Error(response.data.message || 'Failed to create new chat');
    }
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const fetchChatHistory = async (sessionId, userId, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/history`, {
      sessionId,
      user_id: userId,
      object_id: objectId
    });

    console.log('Raw response from server:', response.data); // Log the raw response

    if (response.data.status === 1 && Array.isArray(response.data.history)) {
      // Parse the history to match the expected format
      const parsedHistory = response.data.history.map(msg => {
        console.log('Processing message:', msg); // Log each message

        // Check if msg is an object and has the expected properties
        if (typeof msg === 'object' && msg !== null) {
          return {
            id: msg._id || msg.id || uuidv4(),
            type: msg.type || 'unknown',
            message: msg.message || (msg.data && msg.data.content) || 'No content',
            time: msg.time || (msg.data && msg.data.time) || new Date().toISOString()
          };
        } else {
          console.warn('Unexpected message format:', msg);
          return null;
        }
      }).filter(Boolean); // Remove any null entries

      console.log('Parsed history:', parsedHistory); // Log the parsed history
      return parsedHistory;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error(response.data.message || 'Failed to fetch chat history');
    }
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

export const getRecentChats = async (userId, type, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/sessions`, {
      user_id: userId,
      type,
      object_id: objectId
    });

    if (response.data.status === 1) {
      return response.data.accountSessions;
    } else {
      throw new Error(response.data.message || 'Failed to fetch recent chats');
    }
  } catch (error) {
    console.error('Error fetching recent chats:', error);
    throw error;
  }
};

export const sendMessageToChat = async (sessionId, userMessage, botMessage, userId, type, domainName = '', linkedinUrl = '') => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/insert`, {
      sessionId: sessionId,
      userMessage: userMessage,
      botMessage: botMessage,
      userChatTime: new Date().toISOString(),
      aiChatTime: new Date().toISOString(),
      object_id: '',
      user_id: userId,
      type: type,
      domain_name: domainName,
      linkdin_url: linkedinUrl
    });

    if (response.data.status === 1) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to send message');
    }
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

// New function for LangChain integration
export const processWithLangChain = async (input, chatHistory) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, {
      input,
      chatHistory
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in LangChain processing');
    }
  } catch (error) {
    console.error('LangChain processing failed:', error);
    throw error;
  }
};

// Function to send reviewed data to backend
export const sendReviewedDataToBackend = async (reviewedData) => {
  try {
    const requestBody = {
      prompt: reviewedData.input,
      intents: reviewedData.intents.map(intent => ({
        type: intent.type,
        extractedInfo: Object.entries(intent.editedInfo || {}).reduce((acc, [key, value]) => {
          acc[key] = value === null ? "" : value;
          return acc;
        }, {})
      })),
      userId: reviewedData.userId,// Include the user ID in the request body
      sessionCookie: reviewedData.sessionCookie
    };
    console.log("Sending reviewed data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);

    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in backend response');
    }
  } catch (error) {
    console.error('Error sending reviewed data to backend:', error);
    throw error;
  }
};

// export const processUserInput = async (input, sessionId) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
//       input,
//       sessionId
//     });

//     if (response.data) {
//       return response.data;
//     } else {
//       throw new Error('No data received from processUserInput');
//     }
//   } catch (error) {
//     console.error('Process user input API call failed:', error);
//     throw error;
//   }
// };
export const processUserInput = async (input, sessionId,isCampaignPrompt) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
      input,
      sessionId,
      isCampaignPrompt:isCampaignPrompt?isCampaignPrompt:false
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from processUserInput');
    }
  } catch (error) {
    console.error('Process user input API call failed:', error);
    throw error;
  }
};

export const handleGeneralConversation = async (input, sessionId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/handle_general_conversation`, {
      input,
      sessionId
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from handleGeneralConversation');
    }
  } catch (error) {
    console.error('Handle general conversation API call failed:', error);
    throw error;
  }
};