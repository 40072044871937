

import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import EditableMessage from './editableMessage';
import ReviewPrompt from './reviewPrompt';
import PeopleTable from './peopleTable';
import '../styles/animation.css';
import { faPencilAlt , faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/messageList.css'
import LinkedInDataTable from './linkedinDataTable'; 
import CompanyListTable from './CompanyListTable';
import useOnePgrFunctions from './onepgrSaveComponent/useonepgrFunctions'; 
import OnePgrSaveInterface from './onepgrSaveComponent/onePgrSaveInterface';
const MessageList = ({
  messages,
  onEditMessage,
  editingMessageId,
  onCancelEdit,
  onSaveEdit,
  reviewData,
  onReviewAction,
  isTyping,
  showWelcomeMessage,
  onToggleWelcomeMessage,
  onExamplePromptClick,
  isOpenedByAiCampaign,
  onSaveContacts,
  isEnriched
}) => {
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const [user, setUser] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [showExamples, setShowExamples] = useState(true);
  const [hasUserMessage, setHasUserMessage] = useState(false);
  const [showSaveInterface, setShowSaveInterface] = useState(false);
  const [currentPeopleData, setCurrentPeopleData] = useState(null);

  const { loading, handleSave } = useOnePgrFunctions();

  const handleSaveToOnePgr = (peopleData) => {
    setCurrentPeopleData(peopleData);
    setShowSaveInterface(true);
  };

  const handleSaveConfirm = async (listName) => {
    if (!currentPeopleData) {
      showNotification('No data to save', 'error');
      return;
    }

    const success = await handleSave(listName, currentPeopleData, showNotification);
    if (success) {
      showNotification('Contacts saved successfully', 'success');
      setShowSaveInterface(false);
      setCurrentPeopleData(null);
    }
  };

  const handleSaveCancel = () => {
    setShowSaveInterface(false);
    setCurrentPeopleData(null);
  };

  const showNotification = (message, type) => {
    // Implement your notification logic here
    console.log(`${type}: ${message}`);
    // You can use a toast library or custom notification component
  };


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Filter out null messages and messages with null content
    const filteredMessages = messages.filter(message => 
      message !== null && 
      message.message !== null && 
      message.message !== undefined &&
      message.message !== '' &&
      message.message !== 'No content'
    );
  
    // Check if there's a user message
    const userMessageExists = filteredMessages.some(message => message.type === 'user');
    setHasUserMessage(userMessageExists);
  
    // Only hide examples automatically if it's the first user message
    if (userMessageExists && !hasUserMessage) {
      setShowExamples(false);
    }
  
    if (showWelcomeMessage && showExamples) {
      setAllMessages([...filteredMessages, ...getExamplePrompts()]);
    } else {
      setAllMessages(filteredMessages);
    }
    scrollToBottom();
  }, [messages, isTyping, showWelcomeMessage, showExamples]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
  }, []);

  useEffect(() => {
    // Check if there's a user message
    const userMessageExists = messages.some(message => message.type === 'user');
    setHasUserMessage(userMessageExists);

    // Only hide examples automatically if it's the first user message
    if (userMessageExists && !hasUserMessage) {
      setShowExamples(false);
    }

    if (showWelcomeMessage && showExamples) {
      setAllMessages([...messages, ...getExamplePrompts()]);
    } else {
      setAllMessages(messages);
    }
    scrollToBottom();
  }, [messages, isTyping, showWelcomeMessage, showExamples]);

  // const getExamplePrompts = () => [
  //   {
  //     id: 'find-people-examples',
  //     type: 'system',
  //     content: (
  //       <div>
  //         <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>To find people:</h3>
  //         <ul className="example-prompt-list">
  //           {[
  //             "Find CMO working at Rubrik in California",
  //             "Look for VP of sales, tech lead, and CTO in AI companies in New York",
  //             "Find Sales Directors at SaaS companies in London"
  //           ].map((prompt, index) => (
  //             <li key={index}>
  //               <span>{prompt}</span>
  //               <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
  //                 Try →
  //               </button>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     )
  //   },
  //   {
  //     id: 'create-sequence-examples',
  //     type: 'system',
  //     content: (
  //       <div>
  //         <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>To create sequences:</h3>
  //         <ul className="example-prompt-list">
  //           {[
  //             "Create a sequence for VPs of Marketing focusing on AI adoption using email and LinkedIn",
  //             "Build a campaign for CFOs interested in financial technology innovations via phone calls and email",
  //             "Design an outreach sequence for HR Managers hiring for tech roles through LinkedIn and Twitter"
  //           ].map((prompt, index) => (
  //             <li key={index}>
  //               <span>{prompt}</span>
  //               <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
  //                 Try →
  //               </button>
  //             </li>
  //           ))}
  //         </ul>
  //       </div>
  //     )
  //   }
  // ];

  const getExamplePrompts = () => {
    if(isOpenedByAiCampaign) {
       return [
        {
          id: 'find-people-examples',
          type: 'system',
          content: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>To Create Campaign Plan:</h3>
              <ul className="example-prompt-list">
                {[
                  "Create a campaign for financial service company for sales leaders that are between 5to10 million",
                  // "Develop a campaign for financial services company targeting sales leaders managing portfolios ranging from $5 to $10 million",
                  // "Develop a campaign for real estate firm focused on property developers with project budgets ranging from $50 to $100 million",
                  // "Create a marketing campaign for luxury retail brand aimed at high-net-worth individuals managing portfolios between $1 to $3 million"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else {
       return [
        {
          id: 'find-people-examples',
          type: 'system',
          content: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>To find people:</h3>
              <ul className="example-prompt-list">
                {[
                  "Find CMO working at Rubrik in California",
                  "Look for VP of sales, tech lead, and CTO in AI companies in New York",
                  "Find Sales Directors at SaaS companies in London"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        },
        {
          id: 'create-sequence-examples',
          type: 'system',
          content: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>To create sequences:</h3>
              <ul className="example-prompt-list">
                {[
                  "Create a sequence for VPs of Marketing focusing on AI adoption using email and LinkedIn",
                  "Build a campaign for CFOs interested in financial technology innovations via phone calls and email",
                  "Design an outreach sequence for HR Managers hiring for tech roles through LinkedIn and Twitter"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    }
  }



  const renderMessageContent = (message) => {
    if (!message) {
      console.warn('Received undefined message');
      return null;
    }
  
    let content = message.message || message.content;
  
    // If content is a string, try to parse it as JSON
    if (typeof content === 'string') {
      try {
        content = JSON.parse(content);
      } catch (e) {
        // If parsing fails, it's not a JSON string, so we keep it as is
      }
    }
  
    if (message.type === 'system') {
      return content;
    } else if (message.type === 'user') {
      return <p>{content || ''}</p>;
    } else if (message.type === 'ai' || message.type === 'bot' || message.type === 'general') {
      // Handle the case where content is an object with a 'content' property
      if (content && typeof content === 'object' && content.content) {
        return (
          <div className="markdown-content general-message">
            <ReactMarkdown>{content.content}</ReactMarkdown>
          </div>
        );
      }
  
      // Handle insights
      if (content && content.insights) {
        let insights = content.insights;
        if (typeof insights === 'string') {
          insights = insights.split('\n').filter(item => item.trim() !== '');
        }
        return (
          <div className="insights-container">
            <h3>Insights:</h3>
            <ul>
              {insights.map((insight, index) => (
                <li key={index}>{insight}</li>
              ))}
            </ul>
          </div>
        );
      }
  
      // Handle peopleData
      if (content && content.peopleData) {
        return (
          <div className="people-data-container">
            <PeopleTable people={content.peopleData} />
            {isEnriched && (
              <button 
                className="save-to-button" 
                onClick={() => handleSaveToOnePgr(content.peopleData)}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} /> 
                Save to OnePgr Contacts
              </button>
            )}
          </div>
        );
      }
  
      // Handle linkedInData
      if (content && content.linkedInData) {
        return <LinkedInDataTable data={content.linkedInData} />;
      }
  
      // Handle companyData
      if (content && content.companyData) {
        return <CompanyListTable companies={content.companyData} />;
      }
  
      // If content is a string, render it as markdown
      if (typeof content === 'string') {
        return (
          <div className="markdown-content">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        );
      }
  
      // Fallback for any other type of content
      return <p>{JSON.stringify(content) || 'No content'}</p>;
    }
  
    console.warn('Unhandled message type or structure:', message);
    return <p>Unknown message type</p>;
  };
    
    // Fallback for any other type of content

  const getAvatarContent = () => {
    if (!user) return '';
    if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
    return user.name.charAt(0).toUpperCase();
  };

  const handleToggleExamples = () => {
    const newShowExamples = !showExamples;
    setShowExamples(newShowExamples);
    onToggleWelcomeMessage(newShowExamples);
  };

  return (
    <div className="message-list-container" ref={messageListRef}>
      <div className="welcome-message-toggle">
        {/* <button onClick={handleToggleExamples}>
          {showExamples ? 'Hide Example Prompts' : 'Show Example Prompts'}
        </button> */}
      </div>
      <div className="message-list" style={{ height: '80vh', overflowY: 'auto' }}>
        {allMessages.map((message, index) => (
          <div key={message.id || `message-${index}`} className={`message-container ${message.type}-container`}>
            {message.type === 'user' && message.id === editingMessageId ? (
              <EditableMessage
                message={message}
                onSave={(editedContent) => onSaveEdit(message.id, editedContent)}
                onCancel={onCancelEdit}
              />
            ) : (
              <div className={`message ${message.type}-message`}>
                {message.type === 'user' ? (
                  <div className="user-message-container">
                    <div className="user-avatar-message">
                      {getAvatarContent()}
                    </div>
                    <div className="user-message user-text">
                      {renderMessageContent(message)}
                      <button onClick={() => onEditMessage(message.id)} className="edit-button">
                        <span className="edit-icon"><FontAwesomeIcon icon={faPencilAlt} /></span>
                        <span className="edit-text">Edit</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="ai-message-container">
                    <div className="ai-avatar-message">
                      AI
                    </div>
                    <div className="ai-message ai-text">
                      {renderMessageContent(message)}
                    </div>
                  </div>
                )}
              </div>
            )}
            {index === allMessages.length - 1 && message.type === 'user' && reviewData && (
              <ReviewPrompt
                data={reviewData}
                onAction={onReviewAction}
              />
            )}
          </div>
        ))}
        {isTyping && (
          <div className="elegant-ai-indicator">
            <div className="text">AI Processing</div>
            <div className="orb"></div>
            <div className="orb"></div>
            <div className="orb"></div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {showSaveInterface && (
        <OnePgrSaveInterface
          onSave={handleSaveConfirm}
          onCancel={handleSaveCancel}
        />
      )}
    </div>
  );
};

export default MessageList;