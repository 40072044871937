


// import React from 'react';

// const ReviewPrompt = ({ data, onAction, originalPrompt }) => {
//     const fields = [
//       { key: 'title', label: 'Title' },
//       { key: 'jobSignal', label: 'Job Signal' },
//       { key: 'channel', label: 'Channel' },
//       { key: 'company', label: 'Company' },
//       { key: 'companySize', label: 'Company Size' },
//       { key: 'location', label: 'Location' },
//       { key: 'verticalIndustry', label: 'Vertical Industry' },
//       { key: 'capitalRaised', label: 'Capital Raised' },
//       { key: 'yearsInBusiness', label: 'Years in Business' },
//       { key: 'typeOfBusiness', label: 'Type of Business' },
//       { key: 'seniority', label: 'Seniority' },
//       { key: 'recipient', label: 'Recipient' },
//       { key: 'topic', label: 'Topic' },
//       { key: 'command', label: 'Command' },
//       { key: 'target', label: 'Target' },
//       { key: 'website', label: 'Website' },
//       { key: 'data', label: 'Data to Extract' },
//       { key: 'contactType', label: 'Contact Type' },
//       { key: 'dataFields', label: 'Data Fields to Enrich' }
//     ];
  
//     const mandateScore = 70;

//     // Robust data structure checking
//     const isNewFormat = data && typeof data === 'object' && 'extractedInfo' in data && 'detectedIntents' in data;
//     const extractedInfo = isNewFormat ? data.extractedInfo : {};
//     let intents = [];

//     if (isNewFormat && Array.isArray(data.detectedIntents)) {
//       intents = data.detectedIntents;
//     } else if (Array.isArray(data)) {
//       intents = data;
//     } else if (typeof data === 'object' && !isNewFormat) {
//       intents = [data]; // Single intent object
//     }

//     // Remove duplicate intents
//     intents = intents.filter((intent, index, self) =>
//       index === self.findIndex((t) => t.type === intent.type)
//     );

//     const renderIntentReview = (intentData, index) => {
//       if (!intentData) return null;

//       const isScoreAboveMandate = (intentData.score || 0) >= mandateScore;
//       const mandatoryFields = intentData.mandatoryFields || [];
//       const intentExtractedInfo = isNewFormat ? extractedInfo : (intentData.extractedInfo || {});
//       const missingOrEmptyFields = mandatoryFields.filter(field => 
//         !intentExtractedInfo[field] || intentExtractedInfo[field].trim() === ''
//       );

//       const renderScoreAlert = () => {
//         if (isScoreAboveMandate && missingOrEmptyFields.length === 0) {
//           return (
//             <div className="score-alert good-score">
//               <h4>Good Score!</h4>
//               <p>
//                 This intent has a strong score of {intentData.score}%. You're ready to proceed with this well-structured {intentData.type}.
//               </p>
//             </div>
//           );
//         } else {
//           return (
//             <div className="score-alert low-score">
//               <h4>Improve Your Score</h4>
//               <p>
//                 The current score is {intentData.score}%. To enhance this {intentData.type}, please add the following key information:
//               </p>
//               {missingOrEmptyFields.length > 0 ? (
//                 <ul>
//                   {missingOrEmptyFields.map(field => {
//                     const fieldInfo = fields.find(f => f.key === field);
//                     return <li key={field}>{fieldInfo ? fieldInfo.label : field}</li>;
//                   })}
//                 </ul>
//               ) : (
//                 <p>
//                   Ensure all provided information is specific and detailed. 
//                   Consider using keywords like: {intentData.keywords ? intentData.keywords.join(', ') : 'N/A'}.
//                 </p>
//               )}
//             </div>
//           );
//         }
//       };

//       return (
//         <div key={index} className="intent-review">
//           <h3>Intent {index + 1}: {intentData.type}</h3>
//           {renderScoreAlert()}
//           <div className="review-content">
//             {fields.map(({ key, label }) => {
//               const value = intentExtractedInfo[key];
//               if (value && value !== 'Not specified') {
//                 return (
//                   <div key={key} className="review-item">
//                     <label>{label}:</label>
//                     <input 
//                       type="text" 
//                       value={value} 
//                       readOnly 
//                       className={mandatoryFields.includes(key) ? 'mandatory-field' : ''}
//                     />
//                   </div>
//                 );
//               }
//               return null;
//             })}
//           </div>
//         </div>
//       );
//     };

//     const allScoresAboveMandate = intents.every(intent => (intent.score || 0) >= mandateScore);
//     const allFieldsFilled = intents.every(intent => 
//       (intent.mandatoryFields || []).every(field => 
//         (isNewFormat ? extractedInfo[field] : (intent.extractedInfo || {})[field]) &&
//         (isNewFormat ? extractedInfo[field] : (intent.extractedInfo || {})[field]).trim() !== ''
//       )
//     );

//     if (intents.length === 0) {
//       return <div>No valid intents found. Please try again.</div>;
//     }

//     return (
//       <div className="review-prompt">
//         <h2>Review Extracted Information</h2>
//         {intents.map((intent, index) => renderIntentReview(intent, index))}
//         <div className="review-actions">
//           <button 
//             onClick={() => {
//               const dataWithPrompt = { intents, extractedInfo, originalPrompt };
//               console.log("Data being sent to onAction:", dataWithPrompt);
//               onAction('process', dataWithPrompt);
//             }}
//             disabled={!allScoresAboveMandate || !allFieldsFilled}
//             className={`submit-button ${allScoresAboveMandate && allFieldsFilled ? '' : 'disabled'}`}
//           >
//             Submit
//           </button>
//           <button 
//             onClick={() => onAction('edit', { intents, extractedInfo, originalPrompt })}
//             className="edit-button"
//           >
//             Edit Prompt
//           </button>
//         </div>
//       </div>
//     );
// };

// export default ReviewPrompt;

import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import "../styles/reviewPrompt.css";
import Tooltip from './tooltip';

const ReviewPrompt = ({ data, onAction }) => {
  const [editedData, setEditedData] = useState([]);

  useEffect(() => {
    let parsedIntents = [];
    if (typeof data.intents === 'string') {
      try {
        // Extract the JSON string from the code block
        const jsonString = data.intents.replace(/```json\n|\n```/g, '');
        parsedIntents = JSON.parse(jsonString);
      } catch (error) {
        console.error("Error parsing intents JSON:", error);
      }
    } else if (Array.isArray(data.intents)) {
      parsedIntents = data.intents;
    } else if (data.intents && typeof data.intents === 'object') {
      parsedIntents = [data.intents];
    }

    setEditedData(parsedIntents.map(intent => ({
      ...intent,
      editedInfo: {...(intent.extractedInfo || {})}
    })));
  }, [data]);

  const handleRemoveIntent = (indexToRemove) => {
    setEditedData(prevData => prevData.filter((_, index) => index !== indexToRemove));
  };

  const fields = [
    { key: 'title', label: 'Title' },
    { key: 'jobSignal', label: 'Job Signal' },
    { key: 'channel', label: 'Channel' },
    { key: 'company', label: 'Company' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'location', label: 'Location' },
    { key: 'verticalIndustry', label: 'Vertical Industry' },
    { key: 'capitalRaised', label: 'Capital Raised' },
    { key: 'yearsInBusiness', label: 'Years in Business' },
    { key: 'typeOfBusiness', label: 'Type of Business' },
    { key: 'seniority', label: 'Seniority' },
    { key: 'recipient', label: 'Recipient' },
    { key: 'topic', label: 'Topic' },
    { key: 'command', label: 'Command' },
    { key: 'target', label: 'Target' },
    { key: 'website', label: 'Website' },
    { key: 'dataToExtract', label: 'Data to Extract' },
    { key: 'contactType', label: 'Contact Type' },
    { key: 'dataFields', label: 'Data Fields to Enrich' },
    { key: 'postUrl', label: 'LinkedIn Post URL' },
    { key: 'dataType', label: 'Data Type to Extract' },
    { key: 'postAuthor', label: 'Post Author' },
    { key: 'postDate', label: 'Post Date' }
  ];

  const handleInputChange = (intentIndex, key, value) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[intentIndex].editedInfo[key] = value;
      return newData;
    });
  };

  const renderReviewContent = (intent, intentIndex) => {
    return fields.map(({ key, label }) => {
      const value = intent.editedInfo[key];
      if (value != null && value !== '') {
        return (
          <div key={key} className="review-item">
            <label>{label}:</label>
            <input 
              type="text" 
              value={value}
              onChange={(e) => handleInputChange(intentIndex, key, e.target.value)}
              className={(intent.mandatoryFields || []).includes(key) ? 'mandatory-field' : ''}
            />
          </div>
        );
      }
      return null;
    });
  };

  const isFieldEmpty = (value) => {
    if (typeof value === 'string') {
      return value.trim() === '';
    }
    return value == null || value === '';
  };

  const renderScoreAlert = (intent) => {
    const score = intent.confidence ? Math.round(intent.confidence * 100) : 0;
    const isScoreAboveMandate = score >= 70;
    const mandatoryFields = intent.mandatoryFields || Object.keys(intent.extractedInfo || {});
    const missingFields = mandatoryFields.filter(field => isFieldEmpty(intent.editedInfo[field]));

    if (isScoreAboveMandate && missingFields.length === 0) {
      return (
        <div className="score-alert good-score">
          <h4>Good Score!</h4>
          <p>
            This intent has a strong score of {score}%. You're ready to proceed with this well-structured {intent.type || 'unknown'} intent.
          </p>
        </div>
      );
    } else {
      return (
        <div className="score-alert low-score">
          <h4>Improve Your Score</h4>
          <p>
            The current score is {score}%. To enhance this {intent.type || 'unknown'} intent, please add the following key information:
          </p>
          {missingFields.length > 0 ? (
            <ul>
              {missingFields.map(field => {
                const fieldInfo = fields.find(f => f.key === field);
                return <li key={field}>{fieldInfo ? fieldInfo.label : field}</li>;
              })}
            </ul>
          ) : (
            <p>
              Ensure all provided information is specific and detailed. 
              {intent.keywords && (
                <>Consider using keywords like: {intent.keywords.join(', ')}.</>
              )}
            </p>
          )}
        </div>
      );
    }
  };

  const isAllIntentsValid = () => {
    return editedData.every(intent => {
      const score = intent.confidence ? Math.round(intent.confidence * 100) : 0;
      const mandatoryFields = intent.mandatoryFields || Object.keys(intent.extractedInfo || {});
      return score >= 70 && mandatoryFields.every(field => !isFieldEmpty(intent.editedInfo[field]));
    });
  };

  if (!data || editedData.length === 0) {
    return <div>No data to review</div>;
  }

  return (
    <div className="review-prompt">
      <h2>Review Extracted Information</h2>
      {editedData.map((intent, index) => (
        <div key={index} className="intent-review">
          <div className="intent-header">
            <h3>Intent {index + 1}: {intent.type || 'Unknown'}</h3>
            <Tooltip text="Remove this intent">
              <button 
                onClick={() => handleRemoveIntent(index)}
                className="remove-intent-button"
                aria-label="Remove intent"
              >
                <X size={20} />
              </button>
            </Tooltip>
          </div>
          {renderScoreAlert(intent)}
          <div className="review-content">
            {renderReviewContent(intent, index)}
          </div>
        </div>
      ))}
      <div className="review-actions">
        <button 
          onClick={() => onAction('process', { ...data, intents: editedData })}
          disabled={!isAllIntentsValid() || editedData.length === 0}
          className="submit-button"
        >
          Submit
        </button>
        <button 
          onClick={() => onAction('general', data.input)}
          className="general-button"
        >
          Send General Prompt
        </button>
      </div>
    </div>
  );
};

export default ReviewPrompt;