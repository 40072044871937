


import React, { useState, useRef, useContext, useEffect, useMemo,useCallback} from 'react';
import { useNavigate, useParams , useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import MessageList from './messgeList';
import MessageInput from './messageInput';
import axios from 'axios';
// import { processUserInput , handleGeneralConversation } from '../utils/langchainProcessor';
import { sendProcessedDataToBackend, enrichPeopleData, testBackendConnection } from '../api/api';
import { analyzeFoundPeople } from './dataAnalysis';
import { useCombinedContext } from './context/userContext';
import '../styles/chatInterface.css';
import Sidebar from './sideBar';
import NavBar from './navBar';
import { processWithLangChain, sendReviewedDataToBackend, createNewChat, fetchChatHistory, sendMessageToChat, getRecentChats, processUserInput, handleGeneralConversation, } from '../api/api';
import ReviewPrompt from './reviewPrompt';
import PeopleTable from './peopleTable';
import { v4 as uuidv4 } from 'uuid';



const ChatInterface = ({ onLogout }) => {
  const [messages, setMessages] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const messagesEndRef = useRef(null);
  const messageAreaRef = useRef(null);
  const [peopleData, setPeopleData] = useState(null);
  const [enrichmentMode, setEnrichmentMode] = useState(null);
  // const { user, setUser } = useContext(useCombinedContext);
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [activeSessionId, setActiveSessionId] = useState(null);
  const context = useCombinedContext();
  const { user, setUser } = context;
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const sessionType = "4"
  const location = useLocation();
  const [isEnriched, setIsEnriched] = useState(false);
  const [recentChats, setRecentChats] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const sidebarTriggerRef = useRef(null);
  

  useEffect(() => {
    const initializeChat = async () => {
      if (sessionId) {
        setActiveSessionId(sessionId);
        await loadChatHistory(sessionId);
      } else {
        const lastSessionId = localStorage.getItem('lastSessionId');
        if (lastSessionId) {
          setActiveSessionId(lastSessionId);
          navigate(`/chat/${lastSessionId}`);
          await loadChatHistory(lastSessionId);
        }
      }
    };

    initializeChat();
  }, [sessionId, location.pathname]);

useEffect(() => {
  fetchRecentChats();
}, [user]);

const fetchRecentChats = async () => {
  try {
    const chats = await getRecentChats(user.userId, sessionType);
    setRecentChats(chats);
  } catch (error) {
    console.error('Error fetching recent chats:', error);
  }
};



  const handleExamplePromptClick = (prompt) => {
    handleUserInput(prompt);
  };

  useEffect(() => {
    // Show welcome message on page refresh
    setShowWelcomeMessage(true);
  }, []);

  const handleSidebarMouseLeave = (e) => {
    // Check if the mouse is moving towards the main content
    if (e.clientX > sidebarRef.current.getBoundingClientRect().right) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isSidebarOpen) {
        const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
        if (e.clientX <= triggerRect.right && e.clientY >= triggerRect.top && e.clientY <= triggerRect.bottom) {
          setIsSidebarOpen(true);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isSidebarOpen]);



  const loadChatHistory = async (chatId) => {
    try {
      const history = await fetchChatHistory(chatId, user.userId);
      if (Array.isArray(history) && history.length > 0) {
        setMessages(history);
      } else {
        console.log('No chat history found or empty history returned');
        setMessages([]);
      }
    } catch (error) {
      console.error('Error loading chat history:', error);
      // Optionally, you can set an error state or show a notification to the user
      setMessages([]);
    }
  };

  const handleNewChat = async () => {
    try {
      const newSessionId = await createNewChat(user.userId, sessionType);
      setActiveSessionId(newSessionId);
      localStorage.setItem('lastSessionId', newSessionId);
      navigate(`/chat/${newSessionId}`);
      setMessages([]);
      setShowWelcomeMessage(true);
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    setUser(null);
    onLogout();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    testBackendConnection()
      .then(response => console.log('Backend connection successful:', response))
      .catch(error => console.error('Backend connection failed:', error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (messageAreaRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageAreaRef.current;
        const isScrolledUp = scrollTop < scrollHeight - clientHeight - 10;
        setShowScrollButton(isScrolledUp);
      }
    };

    const messageArea = messageAreaRef.current;
    if (messageArea) {
      messageArea.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messageArea) {
        messageArea.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

 
  const handleUserInput = async (input) => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const newUserMessage = { id: uuidv4(), type: 'user', content: trimmedInput, time: new Date().toISOString() };
      setMessages(prev => [...prev, newUserMessage]);
      setIsTyping(true);

      try {
        let currentSessionId = activeSessionId;
        if (!currentSessionId) {
          currentSessionId = await createNewChat(user.userId, sessionType);
          setActiveSessionId(currentSessionId);
          localStorage.setItem('lastSessionId', currentSessionId);
          navigate(`/chat/${currentSessionId}`);
        }

        if (enrichmentMode === 'yesno') {
          await handleEnrichmentResponse(trimmedInput);
        } else if (enrichmentMode === 'type') {
          await handleEnrichmentResponse(trimmedInput);
        } else {
          const processedData = await processUserInput(trimmedInput, currentSessionId);
        
          if (processedData.needsReview) {
            setReviewData(processedData);
          } else {
            await handleProcessedData(processedData, currentSessionId, trimmedInput);
          }
        }
      } catch (error) {
        console.error('Error processing input:', error);
        const errorMessage = {
          id: uuidv4(),
          type: 'bot',
          content: 'Error processing your request.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleEnrichment = async (enrichType) => {
    setIsTyping(true);
    try {
      if (!peopleData) {
        throw new Error("No people data available for enrichment");
      }
  
      const enrichData = {
        prompt: `Enrich with ${enrichType}`,
        intents: [
          {
            type: "enrich",
            extractedInfo: {
              enrichType: enrichType,
              people: peopleData
            }
          }
        ]
      };
  
      console.log("Enrichment data being sent:", enrichData);
  
      const result = await sendProcessedDataToBackend(enrichData);
      
      console.log("Enrichment result:", result);
  
      if (result.success && result.data && result.data.enriched_people) {
        const updatedPeopleData = result.data.enriched_people;
        setPeopleData(updatedPeopleData);
        
        const successMessage = {
          id: uuidv4(),
          type: 'ai',
          content: `People data enriched successfully with ${enrichType}.`,
          time: new Date().toISOString()
        };
        
        const dataMessage = {
          id: uuidv4(),
          type: 'ai',
          content: { peopleData: updatedPeopleData },
          time: new Date().toISOString()
        };
  
        await sendMessageToChat(activeSessionId, null, successMessage.content, user.userId, sessionType);
        await sendMessageToChat(activeSessionId, null, JSON.stringify(dataMessage.content), user.userId, sessionType);
  
        setMessages(prev => [...prev, successMessage, dataMessage]);
      } else {
        throw new Error(result.error || 'Unexpected response structure from backend');
      }
    } catch (error) {
      console.error('Error enriching data:', error);
      const errorMessage = {
        id: uuidv4(),
        type: 'ai',
        content: `Error enriching data: ${error.message}`,
        time: new Date().toISOString()
      };
      await sendMessageToChat(activeSessionId, null, errorMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setEnrichmentMode(null);
    }
  };

  const handleSendMessage = async (input, sessionId) => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const newUserMessage = { id: uuidv4(), type: 'user', message: trimmedInput, time: new Date().toISOString() };
      
      setMessages(prev => [...prev, newUserMessage]);
     
      setIsTyping(true);
  
      try {
        // Process the user input
        const processedData = await processUserInput(trimmedInput, sessionId);
        setActiveSessionId(processedData.sessionId);
        if (processedData.needsReview) {
          setReviewData(processedData);
         
          // Don't proceed further, wait for user review
        } else {
          // Only proceed if no review is needed
          await handleProcessedData(processedData, sessionId, trimmedInput);
        }
      } catch (error) {
        console.error('Error processing prompt:', error);
        const errorMessage = {
          id: Date.now(),
          type: 'bot',
          message: 'Error processing your request.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
      }
    }
  };
  
  const handleReviewedData = async (reviewedData, sessionId) => {
    setIsTyping(true);
    try {
      const result = await sendReviewedDataToBackend(reviewedData);
      await handleProcessedData(result, sessionId, reviewedData.input);
    } catch (error) {
      console.error('Error processing reviewed data:', error);
      const errorMessage = {
        id: Date.now(),
        type: 'bot',
        message: 'Error processing your request after review.',
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setReviewData(null);
    }
  };
  
  const handleProcessedData = async (processedData, sessionId, userInput) => {
    let aiResponse;
  
    if (processedData.type === 'general') {
      aiResponse = processedData.content;
    } else {
      const result = await sendProcessedDataToBackend(processedData);
      if (result.data && result.data.found_people) {
        await handleFindPeople(result.data, sessionId, userInput);
        return;
      } else {
        aiResponse = processedData.content || 'Processed the request, but no specific action was taken.';
      }
    }
  
    const newAiMessage = { id: uuidv4(), type: 'bot', content: aiResponse, time: new Date().toISOString() };
    setMessages(prev => [...prev, newAiMessage]);
  
    // Insert both user and bot messages together
    await sendMessageToChat(sessionId, userInput, aiResponse, user.userId, sessionType);
  
    fetchRecentChats();
  };
  const handleFindPeople = async (data, sessionId,userInput) => {
    try {
      const foundPeople = data.found_people.people;
      if (!Array.isArray(foundPeople)) {
        throw new Error('Unexpected data structure: found_people.people is not an array');
      }
      setPeopleData(foundPeople);
      setIsEnriched(false);
      const generatedInsights = await analyzeFoundPeople(foundPeople);

      let newMessages = [
        { id: uuidv4(), type: 'ai', content: { insights: generatedInsights } },
        { id: uuidv4(), type: 'ai', content: { peopleData: foundPeople } },
        { 
          id: uuidv4(), 
          type: 'ai', 
          content: `Would you like to enrich the data for these ${foundPeople.length} people? (Yes/No)`
        }
      ];

      for (let message of newMessages) {
        await sendMessageToChat(sessionId, userInput, JSON.stringify(message.content), user.userId, sessionType);
      }

      setMessages(prev => [...prev, ...newMessages]);
      setEnrichmentMode('yesno');
    } catch (error) {
      console.error('Error handling found people:', error);
      const errorMessage = { id: uuidv4(), type: 'ai', content: `Error handling found people: ${error.message}` };
      await sendMessageToChat(sessionId,userInput, errorMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const handleLinkedInScrapeData = async (data, sessionId) => {
    console.log('LinkedIn scrape data:', data);
    try {
      const { linkedin_post_data } = data;
      if (!linkedin_post_data || (!linkedin_post_data.comments && !linkedin_post_data.likes)) {
        throw new Error('Invalid LinkedIn post data structure');
      }
  
      const Comments = linkedin_post_data.comments?.resultObject?.resultObject ? JSON.parse(linkedin_post_data.comments.resultObject.resultObject) : [];
      const Likes = linkedin_post_data.likes?.resultObject?.resultObject ? JSON.parse(linkedin_post_data.likes.resultObject.resultObject) : [];
  
      // Combine likes and comments, removing duplicates
      const combinedData = [...Comments, ...Likes];
  
      const uniqueData = Array.from(new Set(combinedData.map(item => item.profileLink)))
        .map(profileLink => combinedData.find(item => item.profileLink === profileLink));
  
      // Extract post URL for insights
      const postUrl = uniqueData[0]?.postUrl || "Post URL not available";
  
      // Generate insights
      const insights = `
        Post URL: ${postUrl}
        Total interactions: ${uniqueData.length}
        Comments: ${Comments.length}
        Likes: ${Likes.length}
        Most common reaction: ${getMostCommonReaction(uniqueData)}
      `;
  
      let newMessages = [
        { id: uuidv4(), type: 'ai', content: { insights: insights } },
        { id: uuidv4(), type: 'ai', content: { linkedInData: uniqueData } },
        { 
          id: uuidv4(), 
          type: 'ai', 
          content: `Would you like to analyze this LinkedIn post data further? (Yes/No)`
        }
      ];
  
      for (let message of newMessages) {
        await sendMessageToChat(sessionId, null, JSON.stringify(message.content), user.userId, sessionType);
      }
  
      setMessages(prev => [...prev, ...newMessages]);
    } catch (error) {
      console.error('Error handling LinkedIn scrape data:', error);
      const errorMessage = { id: uuidv4(), type: 'ai', content: `Error handling LinkedIn scrape data: ${error.message}` };
      await sendMessageToChat(sessionId, null, errorMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  const getMostCommonReaction = (data) => {
    const reactionCounts = data.reduce((acc, item) => {
      if (item.reactionType) {
        acc[item.reactionType] = (acc[item.reactionType] || 0) + 1;
      }
      return acc;
    }, {});
  
    return Object.entries(reactionCounts).sort((a, b) => b[1] - a[1])[0][0];
  };


  const getLinkedInCookie = async (userId) => {
    const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
    const body = new FormData();
    body.append('id', userId);
  
    try {
      const response = await axios.post(url, body);
      const parsedResponse = response.data;
      console.log("API Response:", parsedResponse);
  
      if (parsedResponse && parsedResponse.response) {
        const userDetails = JSON.parse(parsedResponse.response);
        if (userDetails.api_keys) {
          const apiKeys = JSON.parse(userDetails.api_keys);
          if (apiKeys.linkedIn && apiKeys.linkedIn.cookie) {
            console.log('LinkedIn Cookie:', apiKeys.linkedIn.cookie);
            return apiKeys.linkedIn.cookie;
          }
        }
      }
      throw new Error('LinkedIn cookie not found in the response');
    } catch (error) {
      console.error('Error fetching LinkedIn cookie:', error);
      throw error;
    }
  };

  const handleReviewAction = async (action, data) => {
    console.log('Handling review action:', action, data);
    if (action === 'process') {
      setIsTyping(true);
      try {
        let dataWithUserId = {
          ...data,
          userId: user.userId
        };
  
        if (data.intents && Array.isArray(data.intents)) {
          const linkedInIntent = data.intents.find(intent => intent.type === 'LinkedIn post scraper');
          
          if (linkedInIntent) {
            const linkedinCookie = await getLinkedInCookie('4991');
            console.log('LinkedIn Cookie:', linkedinCookie);
            dataWithUserId = {
              ...dataWithUserId,
              sessionCookie: linkedinCookie,
            };
          }
        }
  
        // Insert user message
        const userMessage = {
          id: uuidv4(),
          type: 'user',
          content: data.input,
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, userMessage]);
        await sendMessageToChat(activeSessionId, data.input, null, user.userId, sessionType);
  
        const result = await sendReviewedDataToBackend(dataWithUserId);
        console.log('Result from sendReviewedDataToBackend:', result);
        
        if (result && result.data && result.data.linkedin_post_data) {
          await handleLinkedInScrapeData(result.data, activeSessionId);
        } else if (result && result.data && result.data.found_people) {
          await handleFindPeople(result.data, activeSessionId);
        } else if (result && result.content) {
          await handleProcessedData(result, activeSessionId, data.input);
        } else {
          throw new Error('Unexpected response structure from backend');
        }
      } catch (error) {
        console.error('Error processing reviewed data:', error);
        const errorMessage = {
          id: Date.now(),
          type: 'bot',
          content: `Error processing your request after review: ${error.message}`,
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
        setReviewData(null);
      }
    } else if (action === 'edit') {
      const lastUserMessage = messages[messages.length - 1];
      setEditingMessageId(lastUserMessage.id);
    } else if (action === 'general') {
      setReviewData(null);
      await handleGeneralPrompt(data.input || data, true);
    }
  };

  const handleGeneralPrompt = async (input, isFromReview = false) => {
    setIsTyping(true);
    try {
      let currentSessionId = activeSessionId;
      if (!currentSessionId) {
        currentSessionId = await createNewChat(user.userId, sessionType);
        setActiveSessionId(currentSessionId);
        navigate(`/chat/${currentSessionId}`, { replace: true });
      }

      // Only add the user message to the state if it's not from a review action
      if (!isFromReview) {
        const userMessage = {
          id: uuidv4(),
          type: 'user',
          content: input,
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, userMessage]);
      }

      const response = await handleGeneralConversation(input, currentSessionId);
      
      if (response.content) {
        const aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: response.content.content || response.content,
          time: new Date().toISOString()
        };
        
        setMessages(prev => [...prev, aiMessage]);
        
        // Send both messages to the chat backend
        await sendMessageToChat(currentSessionId, input, aiMessage.content, user.userId, sessionType);

        // Refresh the recent chats list
        fetchRecentChats();
      } else {
        throw new Error('No content in response');
      }
    } catch (error) {
      console.error('Error processing general prompt:', error);
      setMessages(prev => [...prev, {
        id: uuidv4(),
        type: 'ai',
        content: `Error processing your request: ${error.message}`,
        time: new Date().toISOString()
      }]);
    } finally {
      setIsTyping(false);
      setReviewData(null);
    }
  };
  const handleEnrichmentResponse = async (input) => {
    const lowerInput = input.toLowerCase().trim();
    const userMessage = { id: uuidv4(), type: 'user', content: input, time: new Date().toISOString() };
    setMessages(prev => [...prev, userMessage]);
    await sendMessageToChat(activeSessionId, input, userMessage, user.userId, sessionType);
  
    let aiMessage;
    if (enrichmentMode === 'yesno') {
      if (lowerInput === 'yes') {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: "How would you like to enrich the data? Options are: email, phone, or both.",
          time: new Date().toISOString()
        };
        setEnrichmentMode('type');
      } else if (lowerInput === 'no') {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: 'Alright, no enrichment will be performed.',
          time: new Date().toISOString()
        };
        setEnrichmentMode(null);
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: "I'm sorry, I didn't understand that. Please answer with 'yes' or 'no'.",
          time: new Date().toISOString()
        };
      }
    } else if (enrichmentMode === 'type') {
      if (['email', 'phone', 'both'].includes(lowerInput)) {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: `Starting enrichment process for ${lowerInput}...`,
          time: new Date().toISOString()
        };
        await performEnrichment(lowerInput);
        setEnrichmentMode(null);
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          content: "I'm sorry, I didn't understand that. Please answer with 'email', 'phone', or 'both'.",
          time: new Date().toISOString()
        };
      }
    }
  
    if (aiMessage) {
      await sendMessageToChat(activeSessionId, '', aiMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, aiMessage]);
    }
  };

  const handleSaveContacts = useCallback((contacts) => {
    // Implement the logic to save contacts to OnePageR
    console.log('Saving contacts to OnePageR:', contacts);
    // You can add an API call here to save the contacts
  }, []);

  const performEnrichment = async (enrichmentType) => {
    setIsTyping(true);
    try {
      if (!peopleData) {
        throw new Error("No people data available for enrichment");
      }
  
      const response = await enrichPeopleData(peopleData, enrichmentType);
      if (response.success && response.data.enriched_people) {
        const updatedPeopleData = peopleData.map(person => {
          const enrichedPerson = response.data.enriched_people.find(ep =>
            (ep.first_name + ' ' + ep.last_name).toLowerCase() === (person.first_name + ' ' + person.last_name).toLowerCase()
          );
          return enrichedPerson ? { ...person, ...enrichedPerson } : person;
        });
  
        setPeopleData(updatedPeopleData);
        setIsEnriched(true);
        const successMessage = {
          id: uuidv4(),
          type: 'ai',
          content: `People data enriched successfully with ${enrichmentType}.`,
          time: new Date().toISOString()
        };
        const dataMessage = {
          id: uuidv4(),
          type: 'ai',
          content: { peopleData: updatedPeopleData },
          time: new Date().toISOString()
        };
  
        await sendMessageToChat(activeSessionId, '', successMessage.content, user.userId, sessionType);
        await sendMessageToChat(activeSessionId, '', JSON.stringify(dataMessage.content), user.userId, sessionType);
  
        setMessages(prev => [...prev, successMessage, dataMessage]);
      } else {
        throw new Error(response.error || 'Unknown error occurred during enrichment');
      }
    } catch (error) {
      console.error('Error enriching data:', error);
      const errorMessage = {
        id: uuidv4(),
        type: 'ai',
        content: `Error enriching data: ${error.message}`,
        time: new Date().toISOString()
      };
      await sendMessageToChat(activeSessionId, '', errorMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setEnrichmentMode(null);
    }
  };

  const handleEditMessage = (messageId) => {
    setEditingMessageId(messageId);
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
  };

  const handleSaveEdit = async (messageId, editedContent) => {
    setMessages(prev => prev.map(msg =>
      msg.id === messageId ? { ...msg, content: editedContent } : msg
    ));
    setEditingMessageId(null);

    // Reprocess the edited message
    await handleSendMessage(editedContent);
  };

  const getAvatarContent = () => {
    if (!user) return '';
    if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
    return user.name.charAt(0).toUpperCase();
  };

  const handleToggleWelcomeMessage = () => {
    setShowWelcomeMessage(!showWelcomeMessage);
  };

  return (
    <div className="chat-container">
      <NavBar toggleSidebar={toggleSidebar} onLogout={handleLogout} />
      <div
        ref={sidebarTriggerRef}
        className="sidebar-trigger"
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '10px',
          height: '100%',
          zIndex: 1000
        }}
      />
      <div
        ref={sidebarRef}
        className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
        onMouseLeave={handleSidebarMouseLeave}
      > <Sidebar
          onLogout={onLogout}
          onNewChat={handleNewChat}
          userId={user.userId}
          recentChats={recentChats}
          activeSessionId={activeSessionId}
          onChatSelect={(sessionId) => navigate(`/chat/${sessionId}`)}
        />
      </div>
      <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
        <div className="message-area" ref={messageAreaRef}>
          <MessageList
            messages={messages}
            onEditMessage={handleEditMessage}
            editingMessageId={editingMessageId}
            onCancelEdit={handleCancelEdit}
            onSaveEdit={handleSaveEdit}
            reviewData={reviewData}
            onReviewAction={handleReviewAction}
            isTyping={isTyping}
            showWelcomeMessage={showWelcomeMessage}
            onToggleWelcomeMessage={handleToggleWelcomeMessage}
            onExamplePromptClick={handleExamplePromptClick}
            isEnriched={isEnriched}
            onSaveContacts={handleSaveContacts}
          />

          <div ref={messagesEndRef} />
        </div>

        <MessageInput onSendMessage={handleUserInput} disabled={isTyping} enrichmentMode={enrichmentMode} />
        {showScrollButton && (
          <button onClick={scrollToBottom} className="scroll-button">
            <ChevronDown size={20} />
          </button>
        )}
      </div>
      {user && (
        <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`}>
          <div className="user-fixed-avatar">
            {getAvatarContent()}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatInterface;