// import React from 'react';
// import '../styles/peopleTable.css';

// const PeopleTable = ({ people }) => {
//   if (!people || people.length === 0) {
//     return <p>No people data available.</p>;
//   }

  

//   const formatPerson = (person) => {
//     const formatLocation = () => {
//       if (person.location) return person.location;
//       const city = person.city || '';
//       const country = person.country || '';
//       return city && country ? `${city}, ${country}` : city || country || 'N/A';
//     };

//     return {
//       name: person.name || `${person.first_name} ${person.last_name}`,
//       title: person.title || 'N/A',
//       company: person.company || person.company_name || person.organization_name || (person.organization && person.organization.name) || 'N/A',
//       location: formatLocation(),
//       email: person.email === 'email_not_unlocked@domain.com' ? 'Locked' : (person.email || 'N/A'),
//       phone: person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A',
//       linkedin_url: person.linkedin_url || 'N/A'
//     };
//   };

//   return (
//     <div className="people-table-container">
//       <table className="people-table">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Title</th>
//             <th>Company</th>
//             <th>Location</th>
//             <th>Email</th>
//             <th>Phone</th>
//             <th>LinkedIn</th>
//           </tr>
//         </thead>
//         <tbody>
//           {people.map((person, index) => {
//             const formattedPerson = formatPerson(person);
//             return (
//               <tr key={person.id || index}>
//                 <td>{formattedPerson.name}</td>
//                 <td>{formattedPerson.title}</td>
//                 <td>{formattedPerson.company}</td>
//                 <td>{formattedPerson.location}</td>
//                 <td>
//                   {formattedPerson.email !== 'email_not_unlocked@domain.com' 
//                     ? formattedPerson.email 
//                     : 'Locked'}
//                 </td>
//                 <td>{formattedPerson.phone}</td>
//                 <td>
//                   {formattedPerson.linkedin_url !== 'N/A' ? (
//                     <a 
//                       href={formattedPerson.linkedin_url} 
//                       target="_blank" 
//                       rel="noopener noreferrer"
//                     >
//                       Profile
//                     </a>
//                   ) : (
//                     'N/A'
//                   )}
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default PeopleTable;




import React, { useState } from 'react';
import '../styles/peopleTable.css';

const PeopleTable = ({ people, isEnriched, onSaveContacts}) => {
  console.log('people', people);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);


  const handleCheckboxChange = (person) => {
    setSelectedContacts(prev => 
      prev.some(contact => contact.id === person.id)
        ? prev.filter(contact => contact.id !== person.id)
        : [...prev, person]
    );
  };

  const handleSaveSelected = () => {
    onSaveContacts(selectedContacts);
  };

  const sortedPeople = React.useMemo(() => {
    let sortablePeople = [...people];
    if (sortConfig.key !== null) {
      sortablePeople.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePeople;
  }, [people, sortConfig]);

  if (!people || people.length === 0) {
    return <p>No people data available.</p>;
  }

  const formatPerson = (person) => {
    const formatLocation = () => {
      if (person.location) return person.location;
      const city = person.city || '';
      const country = person.country || '';
      return city && country ? `${city}, ${country}` : city || country || 'N/A';
    };

    return {
      name: person.name || `${person.first_name} ${person.last_name}`,
      title: person.title || 'N/A',
      company: person.company || person.company_name || person.organization_name || (person.organization && person.organization.name) || 'N/A',
      location: formatLocation(),
      email: person.email === 'email_not_unlocked@domain.com' ? 'Locked' : (person.email || 'N/A'),
      phone: person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A',
      linkedin_url: person.linkedin_url || 'N/A'
    };
  };

 

  const filteredPeople = sortedPeople.filter(person => {
    const searchStr = filter.toLowerCase();
    const formattedPerson = formatPerson(person);
    return Object.values(formattedPerson).some(value => 
      value.toString().toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="people-table-container">
      {isEnriched && (
        <div className="table-actions">
          <button onClick={handleSaveSelected} disabled={selectedContacts.length === 0}>
            Save Selected to OnePageR
          </button>
        </div>
      )}
      <table className="people-table table-container">
        <thead>
          <tr>
            {isEnriched && <th>Select</th>}
            <th onClick={() => requestSort('name')}>Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('title')}>Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('company')}>Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('location')}>Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th>Email</th>
            <th>Phone</th>
            <th>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {filteredPeople.map((person, index) => {
            const formattedPerson = formatPerson(person);
            return (
              <tr key={person.id || index}>
                {isEnriched && (
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedContacts.some(contact => contact.id === person.id)}
                      onChange={() => handleCheckboxChange(person)}
                    />
                  </td>
                )}
                <td>{formattedPerson.name}</td>
                <td>{formattedPerson.title}</td>
                <td>{formattedPerson.company}</td>
                <td>{formattedPerson.location}</td>
                <td>
                  {formattedPerson.email !== 'Locked' 
                    ? formattedPerson.email 
                    : 'Locked'}
                </td>
                <td>{formattedPerson.phone}</td>
                <td>
                  {formattedPerson.linkedin_url !== 'N/A' ? (
                    <a 
                      href={formattedPerson.linkedin_url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Profile
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>Total people found: {filteredPeople.length}</p>
    </div>
  );
};

export default PeopleTable;