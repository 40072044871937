import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from 'axios';
import { faPlus, faClock, faChevronDown, faCog, faSignOutAlt,faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getRecentChats } from '../api/api';
import '../styles/sideBar.css';
import { UserContext } from './context/userContext';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';


const Sidebar = ({ onLogout, onNewChat, userId, recentChats, activeSessionId, onChatSelect }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [linkInput, setLinkInput] = useState('');
    const [isRecentsOpen, setIsRecentsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sessionType = "4"
    const [showAllChats, setShowAllChats] = useState(false);


    // useEffect(() => {
    //     if (user && user.userId) {
    //       fetchRecentChats();
    //     }
    //   }, [user]);
    
    

      useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData);
      }, []);
      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const handleMouseEnter = () => {
        setIsSidebarOpen(true);
    };

    const handleMouseLeave = () => {
        setIsSidebarOpen(false);
    };


    const handleRecentChatClick = (sessionId) => {
        console.log("Recent chat clicked. SessionId:", sessionId);
        console.log("Current location:", location.pathname);
        const newPath = `/chat/${sessionId}`;
        console.log("New path:", newPath);
        if (location.pathname !== newPath) {
            console.log("Navigating to:", newPath);
            navigate(newPath, { replace: true });
        } else {
            console.log("Already on the correct path, not navigating.");
        }
    };
  const toggleRecents = () => {
    setIsRecentsOpen(!isRecentsOpen);
    if (!isRecentsOpen) {
      setShowAllChats(false);
    }
  };
  const toggleShowAllChats = () => setShowAllChats(!showAllChats);
  const visibleChats = showAllChats ? recentChats : recentChats.slice(0, 5);

  

    const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleLinkInputChange = (e) => {
        setLinkInput(e.target.value);
    };

    const handleFileUpload = (e) => {
        // Handle file upload logic here
        console.log('File uploaded:', e.target.files);
    };

    function navigateToRoute(route) {
        navigate(route)
    }

  

    const extractMainIntent = (content) => {
        if (!content) return 'Untitled Chat';
        
        const words = content.split(' ');
        const shortIntent = words.slice(0, 5).join(' ');
        return words.length > 5 ? `${shortIntent}...` : shortIntent;
    };

    const formatDate = (date) => {
    
        const formattedDate = moment(date).format('DD/MM HH:mm');
        return formattedDate;
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
   
    const getAvatarContent = () => {
        if (!user) return '';
        if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
    };
 
    return (
        <>
            <div 
                className="sidebar-trigger" 
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '50px',
                    height: '100%',
                    zIndex: 1000
                }}
                onMouseEnter={handleMouseEnter}
            ></div>
        <div 
        className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
        onMouseLeave={handleMouseLeave}
    >
      <button className="new-chat-btn" onClick={onNewChat}>
        <FontAwesomeIcon icon={faPlus} /> Start new chat
      </button>

      <div className="sidebar-section">
        <h3 onClick={toggleRecents} className="accordion-header">
          <FontAwesomeIcon icon={faClock} /> Recents
          <FontAwesomeIcon 
            icon={faChevronRight} 
            className={`accordion-icon ${isRecentsOpen ? 'open' : ''}`} 
          />
        </h3>
        <div 
            className={`accordion-content ${isRecentsOpen ? 'open' : ''} ${showAllChats ? 'show-all' : ''}`}
        //   style={{
        //     maxHeight: showAllChats ? '500px' : 'auto',
        //     overflowY: showAllChats ? 'auto' : 'visible'
        //   }}
        >
           {recentChats.length > 0 ? (
            <>
              <ul className="chat-list">
                {visibleChats.map((chat) => (
                  <li 
                    key={chat._id} 
                    className={`chat-item ${chat._id === activeSessionId ? 'active' : ''}`} 
                    onClick={() => onChatSelect(chat._id)}
                  >
                    <div className="chat-content">
                      <span className="chat-intent">{chat.messages && chat.messages[0]?.data.content || 'Untitled Chat'}</span>
                      <span className="chat-timestamp">{formatDate(chat.messages && chat.messages[0]?.data.time)}</span>
                    </div>
                  </li>
                ))}
              </ul>
              {recentChats.length > 5 && isRecentsOpen && (
                <button className="view-all-btn" onClick={toggleShowAllChats}>
                  {showAllChats ? 'View less ←' : 'View all →'}
                </button>
              )}
            </>
          ) : (
            <p className="no-chats-message">No recent chats to show</p>
          )}
        </div>
      </div>
   
          
            <div className="custom-context-section">
                <h4 onClick={toggleAccordion}>
                    Custom Content
                    <FontAwesomeIcon icon={faChevronRight} className={`accordion-icon ${isAccordionOpen ? 'open' : ''}`} />
                </h4>
                <div className={`accordion-content ${isAccordionOpen ? 'open' : ''}`}>
                    <div className="accordion-inner">
                        <div className="content-selector">
                            <label htmlFor="content-dropdown">Add content:</label>
                            <select id="content-dropdown">
                                <option value="">Select Content Type</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                                <option value="option4">Option 4</option>
                            </select>
                        </div>
                        <div className="radio-options">
                            <label>
                                <input
                                   className='radio-input'
                                    type="radio"
                                    value="onepgr"
                                    checked={selectedOption === 'onepgr'}
                                    onChange={handleOptionChange}
                                />
                                OnePgr
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="upload"
                                    checked={selectedOption === 'upload'}
                                    onChange={handleOptionChange}
                                />
                                Upload
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="addLink"
                                    checked={selectedOption === 'addLink'}
                                    onChange={handleOptionChange}
                                />
                               URL
                            </label>
                        </div>
                        {selectedOption === 'addLink' && (
                            <div className="link-input">
                                <input
                                    type="text"
                                    value={linkInput}
                                    onChange={handleLinkInputChange}
                                    placeholder="Enter link"
                                />
                                <button className='add-btn-url'>Add</button>
                            </div>
                        )}
                        {selectedOption === 'upload' && (
                            <div className="file-upload-area">
                                <input
                                    type="file"
                                    id="file-upload"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="file-upload" className="file-upload-label">
                                    Drop your files here
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="custom-context-section">
                <h4 onClick={()=>{navigateToRoute('/ai-campaign')}}>
                   AI Campaign
                </h4>
            </div>

            <div className="sidebar-footer">
                {user && (
                    <div className="user-info" onClick={toggleDropdown}>
                        <div className="user-avatar">
                            {getAvatarContent()}
                        </div>
                        <div className="user-email">{user.name}</div>
                        <FontAwesomeIcon icon={faChevronDown} className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`} />
                    </div>
                )}
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <button><FontAwesomeIcon icon={faCog} /> Settings</button>
                        <button onClick={onLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export default Sidebar;