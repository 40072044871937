import React, { useState, useContext } from 'react';
import '../styles/loginAi.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CombinedContext } from '../components/context/userContext';

const Login = ({ onLogin }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loginUser } = useContext(CombinedContext);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        loginError: '',
        loginSuccess: '',
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleLoginSuccess = async (userData) => {
        console.log("User data before setting to localStorage:", userData);
        loginUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    
        const profileAttributes = await fetchProfileAttributes(userData.userId);
    
        if (profileAttributes) {
            localStorage.setItem('profileAttributes', JSON.stringify(profileAttributes));
        }
    
        const searchParams = new URLSearchParams(location.search);
        const returnTo = searchParams.get('returnTo');
        const password = location.state?.password;
    
        if (returnTo) {
            navigate(returnTo, { state: { password } });
        } else {
            navigate('/chat');
        }
    };
    const fetchProfileAttributes = async (userId, sessionToken) => {
        console.log('Fetching profile attributes for user:', userId);
        const body = new FormData();
        body.append("id", userId);

        try {
            const response = await axios.post(
                'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1',
                body,
                { withCredentials: true }
            );
            
            const parsedResponse = JSON.parse(response.data.response);
            console.log('Profile Attributes Response:', parsedResponse);

            // Parse nested JSON strings
            const parsedAttributes = {
                ...parsedResponse,
                api_keys: parsedResponse.api_keys ? JSON.parse(parsedResponse.api_keys) : null,
                app_flags: parsedResponse.app_flags ? JSON.parse(parsedResponse.app_flags) : null,
                profile_items: parsedResponse.profile_items ? JSON.parse(parsedResponse.profile_items) : null,
                session_flags: parsedResponse.session_flags ? JSON.parse(parsedResponse.session_flags) : null,
                session_flags2: parsedResponse.session_flags2 ? JSON.parse(parsedResponse.session_flags2) : null
            };

            // Store parsed attributes in localStorage
            localStorage.setItem('parsedProfileAttributes', JSON.stringify(parsedAttributes));
           
            return parsedAttributes;
        } catch (error) {
            console.error('Error fetching profile attributes:', error);
            return null;
        }
    };

    const handleLogin = async () => {
        setIsLoading(true);

        try {
            const url = 'https://onepgr.com/sessions/create3?xhr_flag=1';

            const body = new FormData();
            body.append('login', loginData.email);
            body.append('password', loginData.password);
            body.append('clientname', '11');
            body.append('clientappid', '22');
            body.append('clientappkey', '33');
            body.append('service_name', 'app2');
    
            const response = await axios.post(url, body, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const parsedResponse = JSON.parse(response.data.response);
            console.log("Parsed Response:", parsedResponse);

            if (parsedResponse.success === "0" && parsedResponse.session_token) {
                const userData = {
                    name: parsedResponse.user_login,
                    profilePic: parsedResponse.user_profile_img,
                    userId: parsedResponse.user_id,
                    sessionToken: parsedResponse.session_token
                };

                await handleLoginSuccess(userData);
                setLoginData(prevState => ({
                    ...prevState,
                    loginSuccess: 'Successfully logged in.',
                    loginError: ''
                }));

                ;
            } else {
                setLoginData(prevState => ({ 
                    ...prevState, 
                    loginError: 'Username or password is incorrect.' 
                }));
                setTimeout(() => {
                    setLoginData(prevState => ({ ...prevState, loginError: '' }));
                }, 5000);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoginData(prevState => ({
                ...prevState,
                loginError: 'An error occurred during login. Please try again later.',
            }));
            setTimeout(() => {
                setLoginData(prevState => ({ ...prevState, loginError: '' }));
            }, 5000);
        } finally {
            setIsLoading(false);
        }
    };



    return (
        <div className='login-container'>
            {isLoading ? (
                <div className="spinner-container">
                    <div className="login-spinner"></div>
                </div>
            ) : (
                <div className="login-card">
                    <h1 className="app-title">ai4rep</h1>
                    <div className='login-inputs'>
                        <div className="input-container">
                          
                            <input 
                                type="text" 
                                placeholder="Enter your email or username" 
                                value={loginData.email}
                                onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} 
                            />
                        </div>
                        <div className="input-container">
                           
                            <div className="password-input">
                                <input 
                                    type={showPassword ? 'text' : 'password'} 
                                    placeholder="Enter your password" 
                                    value={loginData.password}
                                    onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEyeSlash : faEye}
                                    className="eye-icon"
                                    onClick={togglePasswordVisibility}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="login-button" onClick={handleLogin}>Log in</button>
                    <a href="/forgot-password" className="forgot-password">Forgot password?</a>
                    <p className='signup-prompt'>Don't have an account? <Link to="https://meet.onepgr.com/signup" className="sign-up">Sign Up</Link></p>
                    
                    {loginData.loginError && (
                        <p className="error-message">{loginData.loginError}</p>
                    )}
                    {loginData.loginSuccess && (
                        <p className="success-message">{loginData.loginSuccess}</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Login;