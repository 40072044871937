import { useState } from 'react';
import axios from 'axios';

const useOnePgrFunctions = () => {
  const [loading, setLoading] = useState(false);

  const createContactList = (name) => {
    const body = new FormData();
    body.append("name", name);
    body.append("description", "1");
    body.append("list_type", "contact");
    return axios.post("https://onepgr.com/lists/create_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
  };

  const uploadMultipleContacts = (contacts_list) => {
    const body = new FormData();
    body.append("contacts_list", JSON.stringify(contacts_list));
    body.append("import_source_id", "3");
    body.append("import_source_name", "import-contact");
    return axios.post("https://onepgr.com/contacts/create2_multi_contacts_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
  };

  const handleSave = async (listName, selectedItems, showNotification) => {
    if (Object.keys(selectedItems).length === 0) {
      showNotification('No contacts selected', 'warning');
      return;
    }
    setLoading(true);
    try {
      const listResponse = await createContactList(listName);
      const listData = JSON.parse(listResponse.data.response);
      const listId = listData.list_id;

      const selectedContacts = Object.values(selectedItems).map(contact => {
        const [city, state, country] = contact.location.split(', ');
        return {
          // ... (rest of the contact mapping logic)
        };
      });

      const contactList = {
        contacts: selectedContacts
      };
      const response = await uploadMultipleContacts(contactList);
      const responseData = JSON.parse(response.data.response);

      if (responseData.success === '0' && responseData.success_msg) {
        showNotification(`${responseData.success_count} contacts saved to OnePgr list "${listName}"`, 'success');
      } else if (responseData.success === '1') {
        showNotification(`Contacts saved to OnePgr list "${listName}" successfully`, 'success');
      } else {
        throw new Error('Unexpected response from server');
      }
      return true;
    } catch (error) {
      console.error('Error saving contacts to OnePgr:', error);
      showNotification(`Failed to save contacts to OnePgr list "${listName}"`, 'error');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSave
  };
};

export default useOnePgrFunctions;