import React, { useState, useRef, useEffect } from 'react';

const EditableMessage = ({ message, onSave, onCancel }) => {
  const [editedContent, setEditedContent] = useState(message.content);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editedContent]);

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  const handleSave = () => {
    onSave(editedContent);
  };

  return (
    <div className="editable-message">
      <textarea
        ref={textareaRef}
        value={editedContent}
        onChange={handleChange}
        className="editable-textarea"
      />
      <div className="edit-actions">
        <button onClick={onCancel} className="cancel-button">Cancel</button>
        <button onClick={handleSave} className="send-button">Send</button>
      </div>
    </div>
  );
};

export default EditableMessage;