

import React, { useState, useRef, useEffect } from 'react';
import { Send } from 'lucide-react';

const MessageInput = ({ onSendMessage, initialValue = '', disabled = false, enrichmentMode = null }) => {
  const [input, setInput] = useState('');
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setInput(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (enrichmentMode === 'yesno') {
      setInput('');
    } else if (enrichmentMode === 'type') {
      setInput('');
    }
  }, [enrichmentMode]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() && !disabled) {
      onSendMessage(input);
      setInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
        setIsInputExpanded(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 200);
      inputRef.current.style.height = `${newHeight}px`;
      setIsInputExpanded(newHeight > 40);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const getPlaceholder = () => {
    if (disabled) return "Please wait...";
    if (enrichmentMode === 'yesno') return "Type 'yes' or 'no'";
    if (enrichmentMode === 'type') return "Type 'email', 'phone', or 'both'";
    return "Message";
  };

  return (
    <form onSubmit={handleSubmit} className="chat-form">
      <textarea
        ref={inputRef}
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={getPlaceholder()}
        className={`chat-input ${isInputExpanded ? 'expanded' : ''} ${disabled ? 'disabled' : ''}`}
        rows={1}
        disabled={disabled}
      />
      <button type="submit" className="chat-button" disabled={disabled || !input.trim()}>
        <Send size={20} />
      </button>
    </form>
  );
};

export default MessageInput;