import React, { useState, useMemo } from 'react';
import '../styles/peopleTable.css';
import '../styles/linkedinDataTable.css';

const LinkedInDataTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item => {
    const searchStr = filter.toLowerCase();
    return Object.values(item).some(value => 
      value && value.toString().toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="linkedin-data-table-container">
      {/* <input
        type="text"
        placeholder="Filter data..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="filter-input"
      /> */}
      <table className="linkedin-data-table table-container">
        <thead>
          <tr>
            <th onClick={() => requestSort('name')}>Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            {/* <th onClick={() => requestSort('job')}>Job {sortConfig.key === 'job' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('reactionType')}>Reaction {sortConfig.key === 'reactionType' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('comment')}>Comment {sortConfig.key === 'comment' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th> */}
            <th>Profile</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={item.profileLink || index}>
              <td>{item.name || `${item.firstName} ${item.lastName}`.trim()}</td>
              {/* <td>{item.job}</td>
              <td>{item.reactionType || 'N/A'}</td>
              <td>{item.comment || 'N/A'}</td> */}
              <td>
                <a href={item.profileLink} target="_blank" rel="noopener noreferrer">
                  Profile
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>Total interactions: {filteredData.length}</p>
    </div>
  );
};

export default LinkedInDataTable;